import React from 'react';
import {AiFillCheckCircle} from 'react-icons/ai';

const Container = (props) => {
  return (
    <div className="experience__frontend">
        <h3>{props.title}</h3>
        <div className="experience__content">
             {
                props.data.map((item) =>{
                   return (
                    <article key={item.id} className='experience__details' >
                        <AiFillCheckCircle className="experience__details_icon"/>
                        <div> 
                            <h4>{item.name}</h4>
                            <small className='text-light'>{item.level}</small>
                        </div>
                    </article>
                  )
                })
             }
        </div>
  </div>

  )
}

export default Container;