import React from 'react'
import './experience.css'
import Container from './Container';
import dataFrontEnd from './data-front-end'
import dataBackEnd from './data-back-end'
import dataTools from './data-tools'
import dataAuthoring from './data-authoring'

const Experience = () => {
 
  return (
    <section id="experience">
      <h5>Capabilities</h5>
      <h2>Experience</h2>
      
      <div className="container experience__container">
        <Container title="Front End Development" data={dataFrontEnd}/>
        <Container title="Back End Development" data={dataBackEnd}/>
        <Container title="Tools / Platforms" data={dataTools}/>
        <Container title="Authoring / Other" data={dataAuthoring}/>
      </div>
    </section>
  )
}

export default Experience