import React from 'react'
import './portfolio.css'

import IMG1 from '../../../assets/images/portfolio/portfolio1.jpg'
import IMG2 from '../../../assets/images/portfolio/portfolio2.jpg'
import IMG3 from '../../../assets/images/portfolio/portfolio3.jpg'
import IMG4 from '../../../assets/images/portfolio/portfolio4.jpg'
import IMG5 from '../../../assets/images/portfolio/portfolio5.jpg'
import IMG6 from '../../../assets/images/portfolio/portfolio6.jpg'

const data = [
  {
      id: 1,
      image: IMG1,
      title: 'ZADV Agency Website (In development)',
      desc: 'Custom Wordpress Website',
      demo:'https://zadvdev.wpengine.com/'    
  },

  {
    id: 2,
    image: IMG2,
    title: 'Zimmerman Mc Donalds Case Study',
    desc: 'Javascript Animations',
    demo:'https://juliovargas.net/archive/mcd/'    
  },

  {
    id: 3,
    image: IMG3,
    title: 'Modern Dashboard',
    desc: 'React Admin Dashboard',
    demo:'https://juliovargas.net/archive/react/moderndash'    
  },

  {
    id: 4,
    image: IMG4,
    title: 'FedEx Latin America',
    desc: 'HTML 5 Scrolling Website',
    demo:'https://juliovargas.net/archive/fedex/solutionist/'    
  },

  {
    id: 5,
    image: IMG5,
    title: 'Advance America',
    desc: 'Custom Wordpress Website',
    demo:'https://advanceamerica.net'    
  },

  {
    id: 6,
    image: IMG6,
    title: 'UrbanAir',
    desc: 'Custom Location Map',
    demo:'https://www.unleashedfamily.com/'    
  }
];

const Portfolio = () => {

  return (
    <section id="portfolio">
        <h5>Work</h5>
        <h2>Portfolio</h2>
        <div className="container portfolio__container">
          {
            data.map(({id, image, title, desc, demo})=>{
              return(
                <article key={id}className='portfolio__item'>
                  <div className="portfolio__image">
                    <img src={image} alt={title} />
                  </div>
                  <h3>{title}</h3>
                  <small>{desc}</small><br></br>
                  <a href={demo} className='btn btn-primary' target='_blank' rel="noopener">View Project</a>
                  
               </article>
              )
            })
          }
        </div>
    </section>
  )
}

export default Portfolio