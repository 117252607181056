
  const tools = [
    {
      id: "0",
      name: 'VS Code', 
      level: 'Advanced'
    },
    {
      id: "1",
      name: 'Postman', 
      level: 'Experienced'
    },
    {
      id: "2",
      name: 'XAMPP', 
      level: 'Advanced'
    },
    {
      id: "3",
      name: 'SourceTree', 
      level: 'Experienced'
    },
    {
      id: "4",
      name: 'BitBucket', 
      level: 'Experienced'
    },
    {
      id: "5",
      name: 'WP Engine', 
      level: 'Advanced'
    },
    {
      id: "6",
      name: 'Wordpress', 
      level: 'Advanced'
    },
    {
      id: "7",
      name: 'JIRA', 
      level: 'Experienced'
    }
  ];

  export default tools;