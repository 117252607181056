import React from 'react'
import './about.css'

import {FiAward} from 'react-icons/fi'
import {FiUsers} from 'react-icons/fi'
import {BsFolderPlus} from 'react-icons/bs'

import AboutImg from '../../../assets/images/pc.png';

const About = () => {
  return (
    <section id="about">
      <h2>About </h2>

      <div className='container about__container'>
        <div className="about__me">
          <div className="about__img">
            <img src={AboutImg} alt="Z building" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FiAward className='about__icon'/>
                <h5>Experience</h5>
                <small>20+ Years </small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
                <h5>Clients</h5>
                <small>Fortune 500 / 100 </small>
            </article>

            <article className='about__card'>
              <BsFolderPlus className='about__icon'/>
                <h5>Projects</h5>
                <small>80+ Completed</small>
            </article>
          </div>
          <p>
            <ul>
              <li>Senior Front-end developer with proven ability to produce quality work.</li>
              <li>Able to	combine creative, artistic talent with the ability to think outside the box.</li>
              <li>Savvy and polished with over 20 years of experience with web and interactive technologies.</li>
            </ul>
          </p>
          <a href="#contact" className='btn btn-primary' >Let's Talk</a>
        </div>
      </div>

    </section>
  )
}

export default About