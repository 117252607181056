import React from 'react'
import './footer.css'
import Menu from '../nav/Menu'

const Footer = () => {
  return (
    <footer>
      <a href="" className='footer__logo'></a>
      <Menu />
      <div className="footer__copyright">
        <small>&copy; 2023 Julio Vargas</small>
      </div>

    </footer>
  )
}

export default Footer