import React, {useState} from 'react'

import { AiOutlineHome } from 'react-icons/ai'
import { AiOutlineUser } from 'react-icons/ai'
import { BiBook } from 'react-icons/bi'
import { RiSuitcaseLine } from 'react-icons/ri'
import { BiMessageSquareDetail } from 'react-icons/bi'

const Menu = (props) => {
  const hasIcon = props.hasIcon;  
  const[activeNav, setActiveNav] = useState('#');

  return (
    <div className='links'>
      <a href="/#" onClick={() => setActiveNav("#")} className={activeNav === '#' ? 'active' : ''}>{ hasIcon ? <AiOutlineHome/> : 'Home' }</a>
      <a href="/#about" onClick={() => setActiveNav("#about")} className={activeNav === '#about' ? 'active' : ''}>{ hasIcon ? <AiOutlineUser/> : 'About' }</a>
      <a href="/#experience" onClick={() => setActiveNav("#experience")} className={activeNav === '#experience' ? 'active' : ''}>{ hasIcon ? <BiBook/> : 'Experience'}</a>
      <a href="/#portfolio" onClick={() => setActiveNav("#portfolio")} className={activeNav === '#portfolio' ? 'active' : ''}>{ hasIcon ? <RiSuitcaseLine/> : 'Portfolio' }</a>
      {/* <a href="/#contact" onClick={() => setActiveNav("#contact")} className={activeNav === '#contact' ? 'active' : ''}>{ hasIcon ? <BiMessageSquareDetail/> : 'Contact' }</a> */}
    </div>
  )
}

export default Menu