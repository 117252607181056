
  const backEnd = [
    {
      id: "0",
      name: 'MySql', 
      level: 'Experienced'
    },
    {
      id: "1",
      name: 'PHP', 
      level: 'Experienced'
    },
    {
      id: "2",
      name: 'Docker', 
      level: 'Familiar'
    },
    {
      id: "3",
      name: 'Node JS', 
      level: 'Expereinced'
    },
    {
      id: "4",
      name: 'SQL Manager', 
      level: 'Familiar'
    },
    {
      id: "5",
      name: 'APIs', 
      level: 'Various'
    }
  ];

  export default backEnd;