
const frontEnd = [
    {
      id: 1,
      name: 'HTML', 
      level: 'Advanced'
    },
    {
      id: 2,
      name: 'JavaScript', 
      level: 'Advanced'
    },
    {
      id: 3,
      name: 'React', 
      level: 'Intermediate'
    },
    {
      id: 4,
      name: 'CSS', 
      level: 'Advanced'
    },
    {
      id: 5,
      name: 'Angular', 
      level: 'Experienced'
    },
    {
      id: 6,
      name: 'Sass / Less', 
      level: 'Advanced'
    },
    {
      id: 7,
      name: 'jQuery', 
      level: 'Advanced'
    },
    {
      id: 8,
      name: 'GSAP', 
      level: 'Advanced'
    }
  ];
  
  export default frontEnd;
  