import React from 'react'
import './header.css'
import CTA from '../shared/ui/CTA'
import REZ from '../../assets/images/julio_vargas_resume.pdf'
import LOGO from '../../assets/images/logo.svg'

const Header = () => {

  return (
    <header>
      <div className="header__container">
        <div className="me">
          <div className="logo"><img src={LOGO}/></div> 
          <div className="name-title">
            <h1>Julio Vargas</h1>
            <div className="divider"></div>
            <h5 className="text-light">Front End Web Developer</h5>
          </div>        
          
         
        </div>
        <div className='header-cta'>
          <CTA class="btn" text="Download Rez" link={REZ} window='new'/>
          {/* <CTA class="btn btn-primary" text="Contact" link="#contact"/> */}
        </div>
      </div>
      <div className='scroll__down'>
          <a href='#contact'>Scroll Down</a>
          <div className="chevron">&#187;</div>
      </div>
    </header>
  )
}

export default Header