import React, {useRef} from 'react'
import './nav.css'
import Menu from './Menu'

const Nav = () => {
  const Nav = useRef();
  
  return (
    <nav ref={Nav}>
      <Menu hasIcon={true}/>
    </nav>
  )
}

export default Nav