import React from 'react';
import { Swiper, SwiperSlide  } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
/* import SwiperGL from '../../../assets/gl/swiper-gl.esm.js'; */
import imageSlider from './imageSlider';
import 'swiper/css';
import 'swiper/css/effect-fade';
/* import '../../../assets/gl/swiper-gl.scss';
 */

import './slider.css';

SwiperCore.use([Autoplay, EffectFade]);

const HeroSlider = () => {
    return (
    <div className='slider-container'>
        <Swiper
         /*  modules={[SwiperGL]}
          effect="gl"
          onBeforeInit={(swiper) => (swiper.params.gl.shader = 'morph-x')} */
          effect="fade"
          speed = {2000}
          loop  ={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
        }}
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
      >
        {
          imageSlider.map((slide )=>{
              return <SwiperSlide>
                    <img className="swiper-gl-image slide-image" src={slide.url} key={slide.id} alt=""/>
                   </SwiperSlide>
            })
          }
      </Swiper>
    </div>
  )
}

export default HeroSlider

