const slide = [
    {
        id: '1',
        url: '../images/slider/image-1.jpg',        
    },
    {
        id: '2',
        url: '../images/slider/image-2.jpg',
    },
    {
        id: '3',
        url: '../images/slider/image-3.jpg',
    },
    {
        id: '4',
        url: '../images/slider/image-4.jpg',
    },
    {
        id: '5',
        url: '../images/slider/image-5.jpg',
    },
  ]

  export default slide;