
  const authoring = [
    {
      id: "0",
      name: 'Dreamweaver', 
      level: 'Experienced'
    },
    {
      id: "1",
      name: 'Illustrator', 
      level: 'Experienced'
    },
    {
      id: "2",
      name: 'Photoshop', 
      level: 'Advanced'
    },
    {
      id: "3",
      name: 'Animate', 
      level: 'Expert'
    },
    {
      id: "4",
      name: 'MailChip', 
      level: 'Familiar'
    },
    {
      id: "5",
      name: 'HotSpot', 
      level: 'Familiar'
    }
    
  ];

  export default authoring;