/* create functional component command: rafce */
import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Footer from './components/footer/Footer'

import Experience from './components/sections/experience/Experience'
import Portfolio from './components/sections/portfolio/Portfolio'
import About from './components/sections/about/About'
import Contact from './components/sections/contact/Contact'
import HeroSlider, {slide} from './components/shared/hero-slider/HeroSlider'



const App = () => {

  return (
    <>
      <HeroSlider></HeroSlider>
      <Header />
      <Nav />
      <About />
      <Experience />
      <Portfolio />
     {/*  <Contact /> */}
      <Footer />
    </>
  )
}

export default App
